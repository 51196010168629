import React from 'react';
import Chat from '../components/chat/Chat';

function ChatPage() {
  return (
    <div>
      <Chat />
    </div>
  );
}

export default ChatPage;