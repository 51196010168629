import React, { useState } from 'react';

function SyncCalendarForm({ onSync }) {
    const [filterHashtag, setFilterHashtag] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [includePattern, setIncludePattern] = useState('');
    const [excludePattern, setExcludePattern] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSync({
            filterHashtag,
            startDate,
            endDate,
            includePattern,
            excludePattern
        });
    };

    return (
        <form onSubmit={handleSubmit} className="mb-8 bg-gray-100 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Sync Calendar</h2>
            <div className="mb-4">
                <label className="block mb-2">Filter Hashtag (optional)</label>
                <input
                    type="text"
                    value={filterHashtag}
                    onChange={(e) => setFilterHashtag(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="#work, #personal, etc."
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Start Date</label>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="w-full p-2 border rounded"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">End Date (optional)</label>
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="w-full p-2 border rounded"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Include Pattern (optional)</label>
                <input
                    type="text"
                    value={includePattern}
                    onChange={(e) => setIncludePattern(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Regular expression to include events"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Exclude Pattern (optional)</label>
                <input
                    type="text"
                    value={excludePattern}
                    onChange={(e) => setExcludePattern(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Regular expression to exclude events"
                />
            </div>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                Sync Calendar
            </button>
        </form>
    );
}

export default SyncCalendarForm;