import React from 'react';
function EventsDisplay({ tags, tagKeys, dateFilterHours, dateSelectionString }) {
    const createRange = (events) => {
      return events.slice(0, 5).map(event => [event.summary, event.duration]);
    };
  
    const calculatePercentage = (hours, totalHours) => {
      if (totalHours === 0) return '0.00';
      return ((hours / totalHours) * 100).toFixed(2);
    };
  
    return (
      <div>
        <h2 className="text-2xl font-bold mb-4">{dateSelectionString}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {tagKeys.map(eventKey => {
            if (tags[eventKey].length > 0) {
              const totalHours = tags[eventKey].reduce((sum, event) => sum + event.duration, 0);
              return (
                <div key={eventKey} className="bg-white shadow rounded-lg p-4">
                  <h3 className="text-xl font-semibold mb-2">{eventKey}</h3>
                  <h4 className="text-md text-gray-600 mb-2">
                    Top {Math.min(tags[eventKey].length, 5)} events:
                    <span className="float-right">Hours</span>
                  </h4>
                  <ul className="mb-4">
                    {createRange(tags[eventKey]).map((event, index) => (
                      <li key={index} className="flex justify-between">
                        <span>{event[0]}</span>
                        <span>{event[1]}</span>
                      </li>
                    ))}
                  </ul>
                  <p className="text-sm text-gray-600">Total hours: {totalHours.toFixed(2)}</p>
                  <p className="text-sm text-gray-600">
                    Percentage of time invested: {calculatePercentage(totalHours, dateFilterHours)}%
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
}
export default EventsDisplay;