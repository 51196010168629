import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CalendarEvents from './CalendarEvents';

function CalendarConnection() {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    checkConnection();
  }, []);

  const checkConnection = async () => {
    try {
      const response = await axios.get('/api/user/connections');
      setIsConnected(response.data.googleCalendar);
    } catch (error) {
      console.error('Error checking connection:', error);
    }
  };

  const handleConnect = async () => {
    try {
      const response = await axios.get('/api/connect/google-calendar');
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error connecting to Google Calendar:', error);
    }
  };

  const handleDisconnect = async () => {
    try {
      await axios.post('/api/disconnect/google-calendar');
      setIsConnected(false);
    } catch (error) {
      console.error('Error disconnecting from Google Calendar:', error);
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-8">Calendar</h1>
      {isConnected ? (
        <>
          <button
            onClick={handleDisconnect}
            className="bg-red-500 text-white px-4 py-2 rounded mb-4"
          >
            Disconnect from Google Calendar
          </button>
          <CalendarEvents />
        </>
      ) : (
        <button
          onClick={handleConnect}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Connect to Google Calendar
        </button>
      )}
    </div>
  );
}

export default CalendarConnection;