import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TopNav from '../common/TopNav';
import FileUpload from './FileUpload';
import Toast from '../common/Toast'; // We'll create this component

function Documents() {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState('');
  const [toast, setToast] = useState({ message: '', type: '' });

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get('/api/user/documents');
      setDocuments(response.data.documents);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to fetch documents');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/user/documents/${id}`);
      fetchDocuments();
      setToast({ message: 'Document deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting document:', error);
      setToast({ message: 'Failed to delete document', type: 'error' });
    }
  };

  const handleUploadSuccess = () => {
    fetchDocuments();
    setToast({ message: 'File uploaded successfully', type: 'success' });
  };

  return (
    <div>
      <TopNav />
      <div className="container mx-auto mt-8 p-4">
        <h1 className="text-3xl font-bold mb-8">Document Management</h1>

        {/* Upload New Documents Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Upload New Document</h2>
          <div className="bg-gray-100 p-6 rounded-lg">
            <FileUpload onUploadSuccess={handleUploadSuccess} setToast={setToast} />
          </div>
        </section>

        {/* Manage Existing Documents Section */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Manage Existing Documents</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {documents.length === 0 ? (
            <p className="text-gray-600">No documents uploaded yet.</p>
          ) : (
            <ul className="bg-white shadow-md rounded-lg divide-y divide-gray-200">
              {documents.map((doc) => (
                <li key={doc.id} className="flex justify-between items-center p-4 hover:bg-gray-50">
                  <div>
                    <span className="font-medium">{doc.file_name}</span>
                    <span className="ml-2 text-sm text-gray-500">({doc.file_type})</span>
                  </div>
                  <button
                    onClick={() => handleDelete(doc.id)}
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          )}
        </section>
      </div>
      {toast.message && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ message: '', type: '' })} />}
    </div>
  );
}

export default Documents;