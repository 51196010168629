import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TopNav from '../common/TopNav';
import SyncCalendarForm from './SyncCalendarForm';

function CalendarEvents() {
  const [events, setEvents] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchEvents();
  }, [searchQuery]);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`/api/calendar/events?search=${searchQuery}`);
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events');
      setLoading(false);
    }
  };

  const handleDelete = async (eventId) => {
    try {
      await axios.delete(`/api/calendar/events/${eventId}`);
      const updatedEvents = { ...events };
      Object.keys(updatedEvents).forEach(key => {
        updatedEvents[key] = updatedEvents[key].filter(event => event.id !== eventId);
      });
      setEvents(updatedEvents);
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Failed to delete event');
    }
  };

  const handleSync = async (syncOptions) => {
    try {
      const response = await axios.post('/api/calendar/sync', syncOptions);
      fetchEvents();
      alert(`Synced ${response.data.events_count} events successfully`);
    } catch (error) {
      console.error('Error syncing calendar:', error);
      setError('Failed to sync calendar');
    }
  };

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-bold mb-8">Calendar Events</h1>
      <SyncCalendarForm onSync={handleSync} />
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search events..."
        className="w-full p-2 mb-4 border rounded"
      />
      {loading ? (
        <p>Loading events...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          {Object.entries(events).map(([tag, tagEvents]) => (
            <div key={tag} className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">{tag}</h2>
              <ul className="space-y-4">
                {tagEvents.map(event => (
                  <li key={event.id} className="bg-white shadow rounded-lg p-4">
                    <h3 className="text-xl font-semibold">{event.summary}</h3>
                    <p className="text-gray-600">{new Date(event.start_time).toLocaleString()} - {new Date(event.end_time).toLocaleString()}</p>
                    {event.description && <p className="mt-2">{event.description}</p>}
                    <button
                      onClick={() => handleDelete(event.id)}
                      className="mt-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CalendarEvents;