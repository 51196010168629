import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TopNav from '../common/TopNav';
import Toast from '../common/Toast';

function Profile() {
  const [user, setUser] = useState({
    name: '',
    username: '',
    email: '',
  });
  const [error, setError] = useState('');
  const [toast, setToast] = useState({ message: '', type: '' });
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [checkingUsername, setCheckingUsername] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/user');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (name === 'username') {
      checkUsernameAvailability(value);
    }
  };

  const checkUsernameAvailability = async (username) => {
    if (username.length >= 6) {
      setCheckingUsername(true);
      if (username === user.username) {
        setUsernameAvailable(true);
      } else {
        try {
          const response = await axios.get(`/api/check-username/${username}`);
          setUsernameAvailable(response.data.available);
        } catch (err) {
          console.error('Error checking username:', err);
          setUsernameAvailable(false);
        }
      }
      setCheckingUsername(false);
    } else {
      setUsernameAvailable(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/user', user);
      setToast({ message: 'Profile updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating user data:', error);
      setToast({ message: 'Failed to update profile', type: 'error' });
    }
  };

  return (
    <div>
      <TopNav />
      <div className="container mx-auto mt-8 p-4">
        <h2 className="text-2xl font-bold mb-4">Profile</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={user.name || ''}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
            <div className="mt-1 relative">
              <input
                type="text"
                name="username"
                id="username"
                value={user.username || ''}
                onChange={handleChange}
                className={`mt-1 block w-full border ${usernameAvailable ? 'border-gray-300' : 'border-red-500'
                  } rounded-md shadow-sm p-2`}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                {checkingUsername && (
                  <svg className="animate-spin h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                )}
                {!checkingUsername && !usernameAvailable && (
                  <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                )}
              </div>
            </div>
            {!usernameAvailable && (
              <p className="mt-1 text-sm text-red-500">This username is not available.</p>
            )}
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={user.email || ''}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <button
            type="submit"
            className={`bg-indigo-600 text-white px-4 py-2 rounded-md ${usernameAvailable ? 'hover:bg-indigo-700' : 'opacity-50 cursor-not-allowed'
              }`}
            disabled={!usernameAvailable}
          >
            Save Changes
          </button>
        </form>
      </div>
      {toast.message && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ message: '', type: '' })}
        />
      )}
    </div>
  );
}

export default Profile;