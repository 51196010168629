import React, { useState, useRef } from 'react';
import axios from 'axios';

function FileUpload({ onUploadSuccess, setToast }) {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) return;

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    try {
      await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (onUploadSuccess) {
        onUploadSuccess();
      }
      setToast({ message: 'Files uploaded successfully', type: 'success' });
    } catch (error) {
      console.error('Error uploading files:', error);
      setToast({ message: 'Error uploading files', type: 'error' });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-6">
      <div className="flex items-center justify-between">
        <input
          type="file"
          onChange={handleFileChange}
          accept=".txt,.docx,.pdf,.xlsx,.pptx,.vtt"
          className="w-2/3 text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          ref={fileInputRef}
          multiple
        />
        <button
          type="submit"
          className="py-2 px-4 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Upload
        </button>
      </div>
      {files.length > 0 && (
        <div className="mt-2">
          <p className="text-sm text-gray-600">{files.length} file(s) selected</p>
        </div>
      )}
    </form>
  );
}

export default FileUpload;