import React, { useState } from 'react';
import TopNav from '../common/TopNav';
import Query from '../chat/Query';
import Results from '../chat/Results';

function Dashboard() {
  const [queryResult, setQueryResult] = useState('');


  return (
    <div>
      <TopNav />
      <div className="container mx-auto mt-8 p-4">
        <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>
        
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Preset Questions</h2>
          <div className="space-y-2">
            {/* {presetQuestions.map((question, index) => (
              <button
                key={index}
                onClick={() => setQueryResult(question)}
                className="block w-full text-left p-2 bg-gray-100 hover:bg-gray-200 rounded"
              >
                {question}
              </button>
            ))} */}
          </div>
        </div>

        <Query setResult={setQueryResult} initialQuery={queryResult} />
        <Results result={queryResult} />
      </div>
    </div>
  );
}

export default Dashboard;