import React from 'react';

function Results({ result }) {
  return (
    <div className="mt-6">
      <h2 className="text-lg font-semibold mb-2">Results:</h2>
      <div className="bg-gray-100 p-4 rounded-md">
        <p className="text-gray-800">{result || 'No results yet. Try submitting a query!'}</p>
      </div>
    </div>
  );
}

export default Results;