import React, { useState, useEffect } from 'react';

function DateSelector({ onDateChosen }) {
  const getCurrentQuarter = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return Math.floor(currentMonth / 3) + 1;
  };

  const [timePeriod, setTimePeriod] = useState('quarter');
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(1);
  const [quarter, setQuarter] = useState(getCurrentQuarter());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    handleApply();
  }, [timePeriod, year, month, quarter]);

  const handleApply = () => {
    let dateInfo;
    if (timePeriod === 'custom') {
      dateInfo = ['custom', startDate, endDate, `${startDate} to ${endDate}`];
    } else if (timePeriod === 'month') {
      dateInfo = ['month', year.toString(), month.toString(), `${year}-${month.toString().padStart(2, '0')}`];
    } else if (timePeriod === 'quarter') {
      dateInfo = ['quarter', year.toString(), quarter.toString(), `${year} Q${quarter}`];
    } else {
      dateInfo = ['year', year.toString(), '', `${year}`];
    }
    onDateChosen(dateInfo);
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push(i);
    }
    return years;
  };

  return (
    <div className="mb-4">
      <div className="flex space-x-4">
        <select
          value={timePeriod}
          onChange={(e) => setTimePeriod(e.target.value)}
          className="border rounded px-2 py-1"
        >
          <option value="custom">Custom</option>
          <option value="month">Month</option>
          <option value="quarter">Quarter</option>
          <option value="year">Year</option>
        </select>
        
        {timePeriod !== 'custom' && (
          <select
            value={year}
            onChange={(e) => setYear(parseInt(e.target.value))}
            className="border rounded px-2 py-1"
          >
            {generateYearOptions().map((y) => (
              <option key={y} value={y}>{y}</option>
            ))}
          </select>
        )}

        {timePeriod === 'month' && (
          <select
            value={month}
            onChange={(e) => setMonth(parseInt(e.target.value))}
            className="border rounded px-2 py-1"
          >
            {[...Array(12)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        )}

        {timePeriod === 'quarter' && (
          <select
            value={quarter}
            onChange={(e) => setQuarter(parseInt(e.target.value))}
            className="border rounded px-2 py-1"
          >
            {[1, 2, 3, 4].map((q) => (
              <option key={q} value={q}>Q{q}</option>
            ))}
          </select>
        )}

        {timePeriod === 'custom' && (
          <>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <button onClick={handleApply} className="bg-blue-500 text-white px-4 py-2 rounded">
              Apply
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default DateSelector;