import React from 'react';
import { Link } from 'react-router-dom';
import TopNav from '../components/common/TopNav';

function HomePage() {
  return (
    <div>
      <TopNav />
      <div className="container mx-auto mt-8 p-4">
        <h1 className="text-4xl font-bold mb-8 text-center">Welcome to HIAI</h1>
        
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Our Features</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>Personalized coaching sessions</li>
            <li>Document management for your coaching materials</li>
            <li>Integration with popular platforms like Google Calendar, Twitter, and Facebook</li>
            <li>Secure user profiles and data management</li>
          </ul>
        </section>
        
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Get Started</h2>
          <p className="mb-4">Ready to begin your coaching journey? Sign up now or log in to your existing account.</p>
          <div className="flex space-x-4">
            <Link to="/register" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">Sign Up</Link>
            <Link to="/login" className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200">Log In</Link>
          </div>
        </section>
      </div>
    </div>
  );
}
export default HomePage;