import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TopNav from '../components/common/TopNav';
import DateSelector from '../components/life-roi/DateSelector';
import SearchOptions from '../components/life-roi/SearchOptions';
import EventsDisplay from '../components/life-roi/EventsDisplay';
import DataDisplay from '../components/life-roi/DataDisplay';

function LifeROI() {
  const [tags, setTags] = useState({});
  const [keys, setKeys] = useState([]);
  const [dateFilterHours, setDateFilterHours] = useState(0);
  const [dateSelectionString, setDateSelectionString] = useState('');
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [searchOptions, setSearchOptions] = useState({});

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((new Date().getMonth() / 3)) + 1;
    getTags(['quarter', currentYear.toString(), currentQuarter.toString(), `${currentYear} Q${currentQuarter}`]);
  }, []);

  const getTags = async (dateInfo) => {
    try {
      let url = `/api/calendar/events?period=${dateInfo[0]}`;
      if (dateInfo[0] === 'custom') {
        url += `&start=${dateInfo[1]}&end=${dateInfo[2]}`;
      } else {
        url += `&year=${dateInfo[1]}`;
        if (dateInfo[0] === 'month' || dateInfo[0] === 'quarter') {
          url += `&${dateInfo[0]}=${dateInfo[2]}`;
        }
      }
      if (searchOptions.searchQuery) {
        url += `&searchType=${searchOptions.searchType}&searchQuery=${encodeURIComponent(searchOptions.searchQuery)}`;
        if (searchOptions.useRegex) {
          url += '&useRegex=true';
        }
      }
      const response = await axios.get(url);
      const formatData = response.data;
      setTags(formatData);
      setKeys(Object.keys(formatData));
      console.log(dateInfo);
      setDateFilterHours(calculateDateFilterHours(dateInfo[1], dateInfo[2], dateInfo[0]));
      setDateSelectionString(dateInfo[3]);
      updateChartData(formatData);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const calculateDateFilterHours = (start, end, period) => {
    if (period === 'year') {
      return 365 * 24; // Approximate hours in a year
    } else if (period === 'quarter') {
      return 91 * 24; // Approximate hours in a quarter
    } else if (period === 'month') {
      return 30 * 24; // Approximate hours in a month
    } else if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffInMilliseconds = endDate - startDate;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      return Math.round(diffInHours);
    }
    return 0; // Default value when start and end are not provided
  };

  const updateChartData = (data) => {
    const labels = Object.keys(data);
  
    const chartData = labels.map(label => 
      data[label].reduce((sum, event) => sum + event.duration, 0)
    );
  
    setChartLabels(labels);
    setChartData(chartData);
  };


  const handleSearch = (options) => {
    setSearchOptions(options);
    // Parse the dateSelectionString to get the correct period information
    const parts = dateSelectionString.split(' ');
    let dateInfo;
    if (parts.length === 2 && parts[1].startsWith('Q')) {
      // Quarter format: "2024 Q4"
      dateInfo = ['quarter', parts[0], parts[1].substring(1), dateSelectionString];
    } else if (parts.length === 2) {
      // Month format: "2024 01"
      dateInfo = ['month', parts[0], parts[1], dateSelectionString];
    } else {
      // Year format: "2024"
      dateInfo = ['year', parts[0], '', dateSelectionString];
    }
    getTags(dateInfo);
  };
  
  return (
    <div>
      <TopNav />
      <div className="container mx-auto mt-8 p-4">
        <h1 className="text-3xl font-bold mb-4">Investment Dashboard</h1>
        <div className="container mx-auto">
            <DateSelector onDateChosen={getTags} />
            <SearchOptions onSearch={handleSearch} />
            <EventsDisplay tags={tags} tagKeys={keys} dateFilterHours={dateFilterHours} dateSelectionString={dateSelectionString} />
            <DataDisplay chartLabels={chartLabels} totalHours={dateFilterHours} eventsHourData={chartData} dateSelectionString={dateSelectionString} />
        </div>
      </div>
    </div>
  );
}

export default LifeROI;