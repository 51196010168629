import React from 'react';
import Dashboard from '../components/dashboard/Dashboard';

function DashboardPage() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default DashboardPage;