import React from 'react';
import TopNav from '../components/common/TopNav';
import CalendarConnection from '../components/calendar/CalendarConnection';

function CalendarPage() {
  return (
    <div>
      <TopNav />
      <CalendarConnection />
    </div>
  );
}

export default CalendarPage;