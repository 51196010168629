function ConnectionItem({ name, connected, onConnect, onDisconnect }) {
    return (
        <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow">
            <div>
                <h2 className="text-xl font-semibold">{name}</h2>
                <p className={`text-sm ${connected ? 'text-green-600' : 'text-red-600'}`}>
                    {connected ? 'Connected' : 'Not connected'}
                </p>
            </div>
            <button
                onClick={connected ? onDisconnect : onConnect}
                className={`px-4 py-2 rounded-md ${connected
                        ? 'bg-red-500 hover:bg-red-600 text-white'
                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                    }`}
            >
                {connected ? 'Disconnect' : 'Connect'}
            </button>
        </div>
    );
}

export default ConnectionItem;
