import React from 'react';
import Documents from '../components/documents/Documents';

function DocumentsPage() {
  return (
    <div>
      <Documents />
    </div>
  );
}

export default DocumentsPage;