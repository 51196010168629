import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function TopNav() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('token'); // Simple check for login status

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      localStorage.removeItem('token');
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="bg-indigo-600 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-white text-xl font-bold">HIAI</Link>
        <div className="space-x-4">
          {isLoggedIn ? (
            <>
              <Link to="/dashboard" className="text-white hover:text-indigo-200">Dashboard</Link>
              <Link to="/documents" className="text-white hover:text-indigo-200">Documents</Link>
              <Link to="/profile" className="text-white hover:text-indigo-200">Profile</Link>
              <Link to="/calendar" className="text-white hover:text-indigo-200">Calendar</Link>
              <Link to="/chat" className="text-white hover:text-indigo-200">Chat</Link>
              <Link to="/life-roi" className="text-white hover:text-indigo-200">Life ROI</Link>
              <button onClick={handleLogout} className="text-white hover:text-indigo-200">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-indigo-200">Sign In</Link>
              <Link to="/register" className="text-white hover:text-indigo-200">Sign Up</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default TopNav;