import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function DataDisplay({ chartLabels, totalHours, eventsHourData, dateSelectionString }) {
    const allocatedHours = eventsHourData.reduce((sum, hours) => sum + hours, 0);
    const unallocatedHours = Math.max(0, totalHours - allocatedHours);
    console.log(unallocatedHours, totalHours, allocatedHours);
  
    const data = {
      labels: [...chartLabels, 'Unallocated time'],
      datasets: [
        {
          data: [...eventsHourData, unallocatedHours],
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
            '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#E0E0E0', // Gray color for unallocated time
          ],
          hoverBackgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
            '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#C0C0C0', // Darker gray for hover
          ],
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: `Time Allocation for ${dateSelectionString}`,
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              const label = context.label || '';
              const value = context.raw || 0;
              const percentage = ((value / totalHours) * 100).toFixed(2);
              return `${label}: ${value.toFixed(2)} hours (${percentage}%)`;
            }
          }
        }
      },
    };
  
    return (
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Data Visualization</h2>
        <div className="h-96">
          <Pie data={data} options={options} />
        </div>
      </div>
    );
  }

export default DataDisplay;