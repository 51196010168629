import React from 'react';
import Connections from '../components/connections/Connections';

function ConnectionsPage() {
  return (
    <div>
      <Connections />
    </div>
  );
}

export default ConnectionsPage;